import React from "react"
import { graphql } from "gatsby"
import Layout from "./Layout"
import { ParallaxProvider } from "react-scroll-parallax"
import FwServices from "../components/services-page/full-width-services"
import HeroSimple from "../components/shared/hero-simple"
import IndustryBoxes from "../components/home-page/industry-boxes-services-page"
import { seoHead } from "../components/utils.js"

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      page(id: $id) {
        id
        title
        content
        uri
        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphUrl
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphImage {
            mimeType
            mediaDetails {
              file
            }
          }
        }
        cfHeroBlockSimpleLeft {
          heroblocksimpleleftIntroText
          heroblocksimpleleftTitle
        }
        cfWhatWeDoMainBlocks {
          wwdMainSectionBlocksRepeat {
            wwdMainSectionTitle
            wwdMainSectionByline
            wwdMainSectionCtaText
            wwdMainSectionCtaLink {
              ... on WPGraphQL_Page {
                uri
              }
            }
            wwdMainSectionBackgroundImage {
              mimeType
              mediaDetails {
                file
              }
            }
          }
        }
        cfWWDParentPage {
          wwdIndustriesTitle
          wwdInfoBlocksIndustries {
            wwdInfoBlockIntroIndustries
            wwdInfoBlockLinkTextIndustries
            wwdInfoBlockTitleIndustries
            wwdInfoBlockLinkUrlIndustries {
              url
            }
            wwdInfoBlockImageIndustries {
              altText
              mediaDetails {
                file
              }
              mimeType
            }
          }
        }
      }
    }
  }
`
const PageTemplateIndustriesParent = ({ data }) => {
  const page = data.wpgraphql.page

  return (
    <>
      <Layout>
        <HeroSimple pagedata={page.cfHeroBlockSimpleLeft} />

        <ParallaxProvider>
          <FwServices sendata={page.cfWhatWeDoMainBlocks} />
        </ParallaxProvider>

        <ParallaxProvider>
          <IndustryBoxes sendata={page.cfWWDParentPage} />
        </ParallaxProvider>
      </Layout>
    </>
  )
}

export default PageTemplateIndustriesParent

// Metadata in head from gatsby ^v4.19.0
// replaces helmet which wasn't generating the tags statically

export const Head = ({ data }) => {
  const page = data.wpgraphql.page
  const seoTags = seoHead(page.seo, page.uri)
  return seoTags
}
