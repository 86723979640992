import React from "react"
import { Link } from "gatsby"
import { Parallax } from "react-scroll-parallax"
import { cloudImage } from "../utils.js"
import * as fwIndStyle from "./full-width-services.module.css"

const FwServices = props => {
  const { wwdMainSectionBlocksRepeat } = props.sendata

  const blockItems = wwdMainSectionBlocksRepeat.map((aBlock, index) => {
    let theBgImg = cloudImage(
      aBlock.wwdMainSectionBackgroundImage.mediaDetails.file,
      aBlock.wwdMainSectionBackgroundImage.mimeType,
      1920,
      true
    )

    return (
      <div
        className={`${fwIndStyle.swiper_slide} swiper-slide d-flex align-items-end align-items-md-center`}
        key={index}
      >
        <Parallax
          translateX={[5, 0]}
          translateY={[0, 0]}
          className={`${fwIndStyle.parallax}`}
        >
          <div
            className={fwIndStyle.bg_imgs}
            style={{
              backgroundImage: theBgImg,
            }}
          ></div>
        </Parallax>
        <div className="container mb-5 mb-md-0 pb-5 pb-md-0">
          <div className="row z_index_top">
            <h2 className="h1">{aBlock.wwdMainSectionTitle}</h2>
            <h3
              className="h4 mt-3 mb-5"
              dangerouslySetInnerHTML={{ __html: aBlock.wwdMainSectionByline }}
            ></h3>
            <Link to={aBlock.wwdMainSectionCtaLink.uri}>
              <button
                title={aBlock.wwdMainSectionCtaText}
                className="ani_white_arrow color-white ps-0"
              >
                {aBlock.wwdMainSectionCtaText}
              </button>
            </Link>
          </div>
        </div>
        <div className={fwIndStyle.full_swiper_mask}></div>
      </div>
    )
  })

  return (
    <div
      className={`${fwIndStyle.hp_full_swiper} hp_full_swiper mb-5 overflow-hidden`}
    >
      {blockItems}
    </div>
  )
}

export default FwServices
