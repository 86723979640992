import React from "react"
import { isSafariLess16 } from "../utils.js"

const HeroSimple = ({ pagedata }) => {
  let title = pagedata.heroblocksimpleleftTitle || "Title....."
  let intro =
    pagedata.heroblocksimpleleftIntroText ||
    "Some intro text goes here, ideally over a couple of lines..."

  const isSafariV16 = isSafariLess16()

  return (
    <div className="hero-small d-flex align-items-center darkgrey_bg mb-0">
      <div className="container pt-4 mt-5 mt-lg-0">
        <div id="hide-row-when-nav-open" className="row">
          <div className="col-12 col-lg-6 color-white">
            <h1 className="header-fade-in mb-4 mb-lg-0">{title}</h1>
          </div>
          <div className="col-12 col-lg-6 color-white animated-headline-wrap position-relative">
            <h2 className="h1 new_style">
              <span
                className={`new_style_addit ${isSafariV16 ? "safari_16" : ""}`}
                dangerouslySetInnerHTML={{ __html: intro }}
              />
            </h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroSimple
